<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-comissions"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      data-anima="sidebar"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel text-left">
          <b-col>
            <b-overlay :show="loading">
              <comission-details 
                v-if="sale_id"
                ref="comission-preview"
                :loading="loading" 
                :sale_id="sale_id"
                :sale="sale"
                :permissions="permissions" 
              />
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>

<script>
import ComissionDetails from "@/components/shared/ComissionDetails";
import AmbassadorService from "@/services/resources/AmbassadorService";
import Cookies from "js-cookie";

const serviceAmbassador = AmbassadorService.build();

export default {
  name: "ComissionPreview",
  components: {
    ComissionDetails,
  },
  props: {
    updateQueryParams: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      visible: false,
      sale_id: null,
      sale: {
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        seller: {
          name: "",
          email: "",
          cellphone: "",
        },
        comission: null,
      },
      permissions: {},
    };
  },
  methods: {
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          this.permissions = dados.reduce((obj, item) => {
            obj[item.name] = true;
            return obj;
          }, {});
        }
      }
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
      this.updateQueryParams(true); // ao enviar o true, a listagem de vendas não será atualizada
    },
    openPanel(sale_id) {
      this.getPermissions();
      this.visible = true;
      this.sale_id = sale_id;
      this.fetchComission();
    },
    fetchComission() {
      this.sale = {
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        seller: {
          name: "",
          email: "",
          cellphone: "",
        },
        comission: null,
      };

      this.loading = true;

      let data = {
        id: this.sale_id,
      };

      serviceAmbassador
        .get('/comission/'+this.sale_id,data)
        .then((response) => {
          this.sale = response;
          this.sale.comission = response.ambassador_balance;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-sidebar {
  padding: 30px;
  padding-top: 20px;
}
</style>
