const Utils = {
    methods: {
        formatTelWhats(tel) {
            if (tel) {
                if (tel.toString().indexOf("+55") > -1) {
                    var tell = tel.replace(/[() -]/g, "");
                    if (tell[0] === "+") {
                        tell = tell.substring(1);
                    }
                    return `https://api.whatsapp.com/send?phone=${tell}`;
                } else {
                    return `https://api.whatsapp.com/send?phone=55${tel.replace(
                    /[() -]/g,
                    ""
                    )}`;
                }
            }
          },
          percent(num, dec = 2) {
              return Number(num/100).toLocaleString('pt-BR',{style: 'percent', minimumFractionDigits:dec}); 
          }
    }
}

export default Utils