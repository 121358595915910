<template>
  <b-modal
    id="indicateds"
    size="lg"
    scrollable
    :visible="false"
    :hide-header-close="false"
    :hide-footer="true"
    :header-class="[
      'bg-white',
      'label-campo',
      'text-secondary',
      'padding-term',
      'border-bottom-0',
      'pb-0',
      'pr-0',
      'pt-0'
    ]"
    :body-class="['scrollbar', 'pt-0']"
    content-class="indicateds"
  >
    <template #modal-header="{ close }">
      <b-row class="w-100 m-0">
        <b-col cols="12" class="text-right p-0">
          <b-button
            size="lg"
            class="btn text-secondary text-decoration-none"
            variant="link"
            @click="close()"
          >
            &times;
          </b-button>
        </b-col>
        <b-col sm="12" md="12" lg="6" class="p-0 align-self-center">
          <h3>{{ $t('views.seller.sales.text_1354') }}</h3>
        </b-col>
        <b-col
          sm="12"
          md="12"
          lg="6"
          class="container-pesquisa align-self-center"
        >
          <div class="inputSearch">
            <b-input-group size="md">
              <b-input-group-append>
                <img
                  src="@/assets/img/icons/search.svg"
                  class="Pointer search"
                />
              </b-input-group-append>
              <b-form-input
                type="text"
                :placeholder="$t('views.seller.sales.text_1356')"
                v-on:input="debounce"
                v-model="search"
                class="input-busca bg-white"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </template>

    <template #default>
      <b-row v-if="!loading">
        <div class="container-fluid">
          <div class="container-table">
            <!-- Table Header -->
            <b-row class="Table-header mb-1">
              <b-col cols="6" class="d-none d-md-block">
                {{ $t('views.seller.sales.text_1350') }}
              </b-col>

              <b-col cols="2" class="d-none d-md-block">
                {{ $t('views.seller.sales.text_1351') }}
              </b-col>

              <b-col cols="2" class="d-none d-md-block">
                {{ $t('views.seller.sales.text_1352') }}
              </b-col>

              <b-col cols="2" class="d-none d-md-block">
                {{ $t('views.seller.sales.text_1355') }}
              </b-col>
            </b-row>

            <!-- Table Body -->
            <template v-if="!loading && indicateds.length > 0">
              <template v-for="(item, key) in indicateds">
                <b-row class="Table-body" v-bind:key="key">
                  <!-- Client -->
                  <b-col cols="6" class="d-none d-md-block dados-cliente">
                    <div>
                      {{
                        item.name && item.name.length > 30
                          ? item.name.substr(0, 30) + '...'
                          : item.name
                      }}
                    </div>

                    <div
                      v-if="item"
                      :id="item.email"
                      v-b-tooltip="{
                        title: item.email,
                        placement: 'top'
                      }"
                    >
                      {{ item.email }}
                    </div>

                    <div v-if="/\#/.test(item.cellphone)">
                      {{ item.cellphone }}
                    </div>

                    <div v-else>
                      <a
                        :href="formatTelWhats(item.cellphone)"
                        target="_blank"
                        class="whats-cliente"
                        ><font-awesome-icon :icon="['fab', 'whatsapp']" />{{
                          item.cellphone
                        }}</a
                      >
                    </div>
                  </b-col>
                  <!-- Creation Data -->
                  <b-col
                    v-if="item.created_at"
                    cols="2"
                    class="d-none d-md-block"
                  >
                    {{ item.created_at | date }}
                  </b-col>
                  <!-- Payment Data -->
                  <b-col
                    v-if="item.ended_at"
                    cols="2"
                    class="d-none d-md-block"
                  >
                    {{ item.ended_at | date }}
                  </b-col>
                  <b-col
                    v-if="!item.ended_at"
                    cols="2"
                    class="d-none d-md-block"
                  ></b-col>
                  <!-- Comission -->
                  <b-col cols="2" class="d-none d-md-block">
                    <b-button
                      :aria-expanded="item.collapse ? 'true' : 'false'"
                      :aria-controls="'collapse-' + item.indicated_id"
                      class="desc-item commission_desc"
                      :class="{ ativo: item.collapse }"
                      @click="item.collapse = !item.collapse"
                      variant="white"
                    >
                      <img src="@/assets/img/icons/eye.svg" alt="Detalhes" />
                    </b-button>
                  </b-col>
                  <b-collapse
                    :id="'collapse-' + item.indicated_id"
                    v-model="item.collapse"
                    class="mt-2 w-100 mx-3"
                  >
                    <b-card no-body class="p-2">
                      <b-row>
                        <b-col cols="4" class="font-weight-bold font-12 pb-2">
                          {{ $t('views.seller.sales.text_1390') }}
                        </b-col>
                        <b-col cols="1" class="font-weight-bold font-12 px-0 py-1 text-right">
                          {{ $t('views.seller.sales.text_1391') }}
                        </b-col>
                        <b-col cols="2" class="font-weight-bold font-12 px-0 py-1 text-center">
                          {{ $t('views.seller.sales.text_1392') }}
                        </b-col>
                        <b-col cols="2" class="border-left font-weight-bold font-12 px-0 py-1 text-right">
                          {{ $t('views.seller.sales.text_1391') }}
                        </b-col>
                        <b-col cols="3" class="font-weight-bold font-12 px-0 py-1 text-center">
                          {{ $t('views.seller.sales.high_ticket_commission') }}
                        </b-col>
                      </b-row>
                      <b-row
                        v-for="(commission, key) in item.commission"
                        :key="key"
                      >
                        <b-col cols="4" class="font-12">
                          {{ $t('views.seller.sales.' + labelFees[key]) }}
                        </b-col>
                        <b-col cols="1" class="px-0 py-1 text-center">
                          <div
                            v-if="
                              item.indicated_seller_fee_type[key] == 'percent'
                            "
                            class="font-12 float-left"
                          >
                            %
                          </div>
                          <div v-else class="font-12 float-left"> R$ </div>
                          <div class="font-12 float-right">
                            {{
                              item.indicated_seller_fee[key]
                                ? formatMoney(item.indicated_seller_fee[key])
                                : '0,00'
                            }}
                          </div>
                        </b-col>
                        <b-col cols="2" class="font-12 px-0 py-1 text-center">
                          {{
                            item.commission[key]
                              ? formatMoney(item.commission[key])
                              : '0,00'
                          }}
                          %
                        </b-col>
                        <b-col cols="2" class="border-left px-0 py-1 text-center">
                          <div
                            v-if="
                              item.high_ticket_indicated_seller_fee_type[key] == 'percent'
                            "
                            class="font-12 float-left pl-5"
                          >
                            %
                          </div>
                          <div v-else class="font-12 float-left pl-5"> R$ </div>
                          <div class="font-12 float-right">
                            {{
                              item.high_ticket_indicated_seller_fee[key]
                                ? formatMoney(item.high_ticket_indicated_seller_fee[key])
                                : '0,00'
                            }}
                          </div>
                        </b-col>
                        <b-col cols="3" class="font-12 px-0 py-1 text-center">
                          {{
                            item.high_ticket_commission[key]
                              ? formatMoney(item.high_ticket_commission[key])
                              : '0,00'
                          }}
                          %
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-collapse>
                </b-row>
              </template>
            </template>

            <!-- Table Pagination -->
            <b-row>
              <b-col>
                <Paginate
                  v-if="indicateds.length > 0"
                  :totalPages="pagination.totalPages"
                  :activePage="pagination.currentPage"
                  :items-per-page="pagination.perPage"
                  @to-page="toPage"
                  @per-page="perPage"
                />
              </b-col>
            </b-row>

            <!-- Table Loading -->
            <div
              v-if="(loading && !indicateds.length) || loading"
              class="Table-body py-4 d-flex justify-content-center align-items-center"
            >
              <b-spinner variant="dark" label="Spinning"></b-spinner>
            </div>

            <!-- Table Not Found -->
            <b-row
              v-if="!loading && !indicateds.length"
              class="Table-body justify-content-center"
            >
              <p class="nao-encontrado">
                {{ $t('views.seller.sales.text_1430') }}
              </p>
            </b-row>
          </div>
        </div>
      </b-row>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-modal>
</template>

<script>
import AmbassadorService from '@/services/resources/AmbassadorService'
import Paginate from '@/components/shared/Paginate'
import Utils from '@/mixins/utils'
import moment from 'moment'
import Money from '@/mixins/money'

const serviceAmbassador = AmbassadorService.build()

export default {
  name: 'Indicateds',
  mixins: [Utils, Money],
  components: {
    Paginate
  },
  data() {
    return {
      loading: false,
      search: null,
      indicateds: [],
      collapse: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
        sortBy: 'created_at',
        sortDesc: true
      },
      labelFees: {
        BOLETO: 'text_1393',
        CREDIT_CARD: 'text_1394',
        INTERNATIONAL_CREDIT_CARD: 'text_1395',
        PAYPAL: 'text_1396',
        PIX: 'text_1397'
      }
    }
  },
  computed: {
    userID() {
      return this.$store.getters.getProfile.id
    }
  },
  methods: {
    openModal() {
      this.indicateds = []
      this.fetchIndicatedAmbassador()
    },
    fetchIndicatedAmbassador(search = null) {
      this.loading = true
      this.indicateds = []
      this.pagination.totalPages = 1

      let data = {
        per_page: this.pagination.perPage,
        page: this.pagination.currentPage,
        order_by: this.pagination.sortBy,
        order_type: this.pagination.sortDesc ? 'DESC' : 'ASC',
        url: '/indicateds/' + this.userID
      }

      if (search != null && typeof search == 'string' && search.length > 0) {
        data.search = search
      }
      serviceAmbassador
        .search(data)
        .then(resp => {
          let current_page = resp.total / this.pagination.perPage
          current_page = Math.ceil(current_page)
          this.pagination.totalPages = current_page

          resp.indicateds.forEach(element => {
            this.indicateds.push({
              indicated_id: element.indicated_id,
              name: element.name,
              email: element.email,
              cellphone: element.cellphone,
              cpf_cnpj: element.cpf_cnpj,
              created_at: element.created_at,
              ended_at: moment(element.ended_at).format('YYYY-MM-DD'),
              collapse: false,
              indicated_seller_fee: element.indicated_seller_fee,
              indicated_seller_fee_type: element.indicated_seller_fee_type,
              commission: element.comission,
              high_ticket_indicated_seller_fee: element.high_ticket_indicated_seller_fee,
              high_ticket_indicated_seller_fee_type: element.high_ticket_indicated_seller_fee_type,
              high_ticket_commission: element.high_ticket_comission
            })
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    debounce: _.debounce(function () {
      if (
        (this.search != null &&
          typeof this.search == 'string' &&
          this.search.length > 2) ||
        this.search.length == 0
      ) {
        this.fetchIndicatedAmbassador(this.search)
      }
    }, 500),
    toPage(page) {
      this.pagination.currentPage = page
      this.fetchIndicatedAmbassador(this.search)
    },
    perPage(qtd) {
      this.pagination.currentPage = 1
      this.pagination.perPage = qtd

      this.fetchIndicatedAmbassador(this.search)
    }
  },
  mounted() {
    this.openModal()
  }
}
</script>

<style scoped>
.font-12 {
  font-size: 12px;
}
.modal-content {
  padding: 0px 0px !important;
  animation: showTop 0.4s forwards !important;
}
.padding-term {
  padding: 0 2rem;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.dados-campo {
  font-weight: normal;
  font-size: 14px;
  margin-top: 3px;
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 33, 99, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 33, 99, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 190px;
  font-size: 14px !important;
  color: #81858e !important;
  text-align: center;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.inputSearch .input-group {
  border: 1px solid #eee;
  height: 42px;
  border-radius: 5px;
  right: -15px;
  width: 290px;
}
.inputSearch .input-group-append {
  margin-left: 0;
}
.input-busca {
  width: 280px;
  height: 30px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px !important;
  padding: 20px 30px !important;
  padding-left: 40px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e !important;
  font-size: 14px !important;
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 10px;
  top: 13px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 10px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: #002363;
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: '';
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}
.button {
  width: 210px;
  height: 55px;
  border-radius: 5px;
  background: #2a63ab;
  color: #fff;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button--black {
  background: #333;
  color: #fff;
}
.button:hover {
  transform: scale(1);
}
.inverted--black {
  filter: invert(100);
}
.title-report {
  color: #333;
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}
.commission_desc.ativo img {
  transition: 0.3s;
  transform: rotate(180deg);
}
</style>
